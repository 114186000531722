export const Colors = {
  white: '#FFFFFF',
  black: '#00000',
  slate: '#F4F8FB',
  darkBlue: '#005FF9',
  lightBlue: '#00D8E3',
  green: '#01DF7A',
  lightGreen: '#A9FFD9',
  gray: '#CCCCCC',
  darkGray: '#424242',
  lightGray: '#EFF0F7',
  red: '#FF0000',
  lightRed: '#FFD0D0',
  orange: '#FF7A00',
  yellow: '#FFE600',
}